import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";

const Header = () => {
  return (
    <div className="header flex flex-col" id="header">
      <Navbar />

      <div className="container flex">
        <div className="header-content">
          <h1
            data-aos="fade-up"
            className="text-uppercase text-white op-07 fw-6 ls-2"
          >
            בניית אתרים בקוד
          </h1>
          <p
            data-aos="fade-up"
            data-aos-delay="300"
            className="text-white fw--6 header-title"
          >
            בנייה ועיצוב אתרים המותאמים אישית לכל סוגי העסקים. אנו מתמחים בקידום
            ובניית אתרים, דפי נתיחה ואתרי תדמית באמצעות קוד, המותאמים אישית לכל
            לקוח בעזרת הטכנולוגיה הכי מתקדמת לבניית אתרים ידידותיים למחשב
            ולנייד.
          </p>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className="btn-groups flex"
          >
            <a href="#contact" className="btn-item bg-blue fw-4 ls-2">
              צרו קשר
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
