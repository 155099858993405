import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4"; // וודא ש-ReactGA מיובא כאן

const FormDetail = ({ serviceID, templateID, publicKey }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(serviceID, templateID, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          setLoading(false);
          toast.success("פרטיך התקבלו בהצלחה 🚀");
          e.target.reset();

          // כאן אנחנו עוקבים אחרי שליחת הטופס ל-Google Analytics
          ReactGA.event({
            category: "Contact Form",
            action: "Submitted the contact form",
          });
        },
        (error) => {
          setLoading(false);
          console.log("FAILED...", error.text);
          toast.error("שגיאה בשליחת ההודעה. נסה שוב.");
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <form ref={form} onSubmit={sendEmail} className="contact-form mx-auto">
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="שם"
            id="name"
            name="user_name"
            required
          />
        </div>
        <div>
          <input
            type="number"
            className="form-control"
            placeholder="מס' טלפון"
            id="phone"
            name="user_phone"
            required
          />
        </div>
        <div>
          <textarea
            rows="2"
            className="form-control"
            placeholder="הודעה"
            id="message"
            name="message"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className={`bg-blue text-white submit-btn fw-3 fs-22 ${
            loading ? "loading" : ""
          }`}
          disabled={loading}
        >
          {loading ? "שולח..." : "שלח :)"}
        </button>
      </form>
    </>
  );
};

export default FormDetail;
