import React, { useEffect } from "react";
import "./Whatsapp.css";
// import images from "../../constants/images";
import { FaWhatsapp } from "react-icons/fa";
import "aos/dist/aos.css";
import Aos from "aos";

const Whatsapp = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="whatsapp">
      <a
        href="https://wa.me/972525775880?text=היי, אשמח לקבל פרטים נוספים בנוגע לבניית אתר :)"
        target="_blank"
        rel="noreferrer"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default Whatsapp;
