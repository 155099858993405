import service_1 from "../assets/images/service1.png";
import service_2 from "../assets/images/service2.png";
import service_3 from "../assets/images/service3.png";
import work_1 from "../assets/images/work1.png";
import work_2 from "../assets/images/work2.png";
import work_3 from "../assets/images/work3.png";
import work_4 from "../assets/images/work4.png";
import work_5 from "../assets/images/work5.png"
import header_bg from "../assets/images/header-bg.jpg";
import Whatsapp_logo from "../assets/images/whatsapp_icon.png";

const images = {
  header_bg,
  service_1,
  service_2,
  service_3,
  work_1,
  work_2,
  work_3,
  work_4,
  work_5,
  Whatsapp_logo,
};

export default images;
