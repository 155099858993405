import React from "react";
import "./Footer.css";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer bg-dark section-p" id="footer">
      <div className="container">
        <div className="section-title">
          <h3 className="text-white">עקבו אחרינו</h3>
        </div>
        <div className="footer-content">
          <ul className="footer-social-links flex flex-c">
            <li>
              <a href="/" className="text-white facebook">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="/" className="text-white twitter">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/_web4u/"
                className="text-white instagram"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
          </ul>
          <p className="text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text">
            All Rights Reserved &copy; 2024, YK
          </p>
        </div>
      </div>
    </div>
  ); 
};

export default Footer;
