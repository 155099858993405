// import React, { useState } from "react";
import { useEffect } from "react";
import { works } from "../../constants/data";
import "./Work.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Work = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="work section-p bg-grey" id="work">
      <div className="container" data-aos="fade-up">
        <div className="work-content">
          <div className="section-title">
            <h3 className="text-dark">תיק עבודות</h3>
            <p className="text">האתרים שאנחנו בונים.</p>
          </div>

          <div className="work-list grid">
            {works.map((work, index) => {
              return (
                <div className="work-item text-center" key={index}>
                  <a href={work.link} target="_blank" rel="noreferrer">
                    <img src={work.image} alt="" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
