import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Services from "../Services/Services";
import Work from "../Work/Work";
import Contact from "../Contact/Contact";

const Homepage = () => {
  return (
    <div>
      <Helmet>
        <title>בניית אתרים בקוד - פתרונות מתקדמים לפיתוח אתרים | Web4u</title>
        <meta
          name="description"
          content="בניית אתרים בקוד ממומחים לפיתוח אתרים מותאם אישית: פיתוח בקוד נקי ויעיל שיביא את העסק שלכם לקדמת הבמה. עיצוב ייחודי, חוויית משתמש מתקדמת ופיתוח חכם מותאם למנועי חיפוש>>"
        />
      </Helmet>
      <Header />
      <Services />
      <Work />
      <Contact />
    </div>
  );
};

export default Homepage;
