import React, { useState, useEffect } from "react";
import "./Services.css";
import { services } from "../../constants/data";
import Aos from "aos";
import "aos/dist/aos.css";

const Services = () => {
  const [activePopup, setActivePopup] = useState(null);

  useEffect(() => {
    Aos.init();
  }, []);

  // ביטול גלילה כאשר הפופאפ פתוח
  useEffect(() => {
    if (activePopup !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [activePopup]);

  // סגירת הפופאפ הלחיצה על אסקייפ
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopup();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  

  const handleOpenPopup = (index) => {
    setActivePopup(index);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  return (
    <>
      <div className="services section-p" id="services">
        <div className="container" data-aos="fade-up">
          <div className="services-content">
            <div className="section-title">
              <h3 className="text-dark">השירותים שלנו</h3>
              <p className="text">מה אנחנו מציעים לכם.</p>
            </div>

            <div className="services-list grid">
              {services.map((service, index) => (
                <div
                  className="services-item text-center"
                  key={index}
                  onClick={() => handleOpenPopup(index)}
                >
                  <div className="services-item-img">
                    <img src={service.image} className="mx-auto" alt="" />
                  </div>
                  <div className="services-item-text">
                    <h2 className="fw-5">{service.title}</h2>
                    <p className="fs-18 text">{service.paragraph}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {activePopup !== null && (
        <div className="popup active" onClick={handleClosePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="popup-close" onClick={handleClosePopup}>
              &times;
            </span>
            <h2>{services[activePopup].title}</h2>
            <p>{services[activePopup].popup}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
