import React, { useEffect } from "react";
import "./Contact.css";
import Aos from "aos";
import "aos/dist/aos.css";
import FormDetail from './FormDetail'; // היבוא של הקומפוננטה החדשה

const Contact = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="contact section-p" id="contact">
      <div className="container" data-aos="zoom-in">
        <div className="section-title">
          <h3 className="text-dark">צרו איתנו קשר</h3>
          <p className="text">בואו נבנה לכם אתר!</p>
        </div>

        <div className="contact-map-form">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19295.345111734867!2d34.79698122235346!3d31.249327341342294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15026640029f8777%3A0x8dee8012deb5dd8!2z15HXkNeoINep15HXog!5e0!3m2!1siw!2sil!4v1711558241358!5m2!1siw!2sil"
              width="100%"
              height="450"
              style={{ border: "0" }}
              title="title"
            ></iframe>
          </div>

          <FormDetail 
            serviceID="service_d4ujcph"
            templateID="template_p9w3ynu"
            publicKey="pv_L3yy-dbxqxoN7r"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
